import React from "react";
import clsx from "clsx";
import useStyles from "./SpecialAlert.style.js";
import {
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import * as noImage from "./../../assets/images/noImage.png";
import CheckoutLoader from "../CheckoutLoader/CheckoutLoader.js";

const SpecialAlert = ({
  title,
  open,
  handleClose,
  image,
  handleFunc,
  isLoading = false,
  isCheckout = false,
  handleForceClose,
  updateBtn = false,
  isCartClear = false,
  isWalletModal = false,
  handleCloseModal = false,
  isCancel = false,
  isWalletRedeemModal = false,
  isDifferModal = false,
  isAvailibilityModal = false,
}) => {
  const classes = useStyles();
  // console.log("renderinggggggggggggg")

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      className={classes.customPopup}
      onClose={
        updateBtn || isWalletModal
          ? null
          : isCheckout
          ? handleForceClose
          : handleClose
      }
    >
      {/* <DialogContent className={classes.customPopup} >
                <DialogContentText className={classes.customText}>
                {title}
                </DialogContentText>
            </DialogContent> */}
      {isLoading ? (
        <CheckoutLoader />
      ) : (
        <>
          {image && (
            <div className={classes.imgDiv}>
              <CardMedia
                className={classes.placeholderImg}
                component="img"
                src={image || noImage.default}
              />
            </div>
          )}
          {title && (
            <DialogTitle className={classes.customText}>{title}</DialogTitle>
          )}
          <DialogActions className={classes.customAction}>
            {isDifferModal ? (
              <Button
                className={`${classes.actionText} ${classes.getItFreeBtn}`}
                onClick={handleFunc}
              >
                Yes
              </Button>
            ) : null}

            {isCancel && isWalletModal && (
              <Button
                className={`${classes.actionText} ${classes.readyToPayBtn}`}
                onClick={handleCloseModal}
              >
                I am Ready To Pay
              </Button>
            )}
            {isCancel && !isWalletModal && (
              <Button
                className={`${classes.actionText}`}
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
            )}
            {isAvailibilityModal && (
              <Button className={`${classes.actionText}`} onClick={handleClose}>
                Change Time
              </Button>
            )}
            {updateBtn ? (
              <Button
                className={classes.updateActionText}
                onClick={handleClose}
              >
                Update
              </Button>
            ) : isCartClear ? (
              <Button className={classes.actionText} onClick={handleClose}>
                Clear
              </Button>
            ) : isWalletModal ? (
              <Button
                className={`${classes.actionText} ${classes.getItFreeBtn}`}
                onClick={handleClose}
              >
                Get It Free
              </Button>
            ) : isDifferModal ? (
              <Button
                className={`${classes.actionText} ${classes.readyToPayBtn}`}
                onClick={handleClose}
              >
                No
              </Button>
            ) : isAvailibilityModal ? (
              <Button
                className={`${classes.actionText} ${classes.readyToPayBtn}`}
                onClick={handleForceClose}
              >
                Remove products
              </Button>
            ) : (
              <Button className={classes.okActionText} onClick={handleClose}>
                Ok
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default SpecialAlert;
