import { makeStyles } from "@material-ui/core/styles";
import { calculateFontSize } from "../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    // whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  title: {
    // ...theme.typography.rockwell,
    fontSize: calculateFontSize(20, theme.palette.common.fontVariation),
    fontWeight: 700,
    margin: theme.spacing(0.4),
    color: theme.palette.common.headerFontColor,
  },
  cartTitle: {
    fontSize: calculateFontSize(20, theme.palette.common.fontVariation),
    fontWeight: 700,
    margin: theme.spacing(0.4),
    color: theme.palette.common.themeFontColor,
  },
  location: {
    display: "flex",
    alignItems: "center",
    fontSize: calculateFontSize(12, theme.palette.common.fontVariation),
    fontWeight: 400,
    color: theme.palette.common.headerFontColor,
  },
  locationSpl: {
    display: "flex",
    alignItems: "center",
    fontSize: calculateFontSize(12, theme.palette.common.fontVariation),
    fontWeight: 400,
    color: theme.palette.common.themeFontColor,
  },
  headerLogoDiv: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  headerLogo: {
    height: "45px",
    width: "auto",
  },
  iconBtn: {
    color: theme.palette.common.white,
  },
  blackColor: {
    // color: theme.palette.primary.main
    color: "#000",
  },
  noPadingIcon: {
    padding: theme.spacing(0),
  },
}));

export default useStyles;
