import React, { useState } from "react";
import useStyles from "./OrderTypeModal.style";
import Modal from "../../components/Material/Modal/Modal";
import { Divider, Typography } from "@material-ui/core";
import SelectOrderType from "../Checkout/SelectOrderType/SelectOrderType";
import { useDispatch } from "react-redux";
import useCheckoutForm from "../../CustomHooks/useCheckoutForm";
import useWalletPoints from "../../CustomHooks/useWalletPoints";
import { setOrderTypeModal } from "../../store/Cart/CartActions";
import { resetWallet } from "../../store/Profile/ProfileActions";

const OrderTypeModal = ({ open, setOpen }) => {
  const classes = useStyles();
  const { formValues, setFormValues } = useCheckoutForm();
  const [openOrderTypeModal, setOpenOrderTypeModal] = useState(true);
  const dispatch = useDispatch();
  const { resetWalletProducts } = useWalletPoints();

  const onSelectOrderType = (val) => {
    setFormValues({ orderType: val });
    localStorage.setItem("orderType", val);
    // setOpenOrderTypeModal(false);
    dispatch(resetWallet());
    resetWalletProducts(2);
    dispatch(setOrderTypeModal(false));
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      customView={true}
      modelContainer={classes.orderTypeModalContainer}
    >
      <div className={classes.orderTypeModal}>
        <div className={classes.modalTitleSection}>
          <Typography
            className={classes.modalTitle}
            variant="h1"
            align="center"
            paragraph
          >
            {"Select Order Type"}
          </Typography>
          <Divider />
        </div>

        <SelectOrderType
          value={formValues.orderType}
          onSelect={onSelectOrderType}
        />
      </div>
    </Modal>
  );
};

export default OrderTypeModal;
