import { all } from "redux-saga/effects";

// Sagas
import { ThemeSaga } from "../Theme/ThemeSagas";
import {
  RestaurantSaga,
  TableDetailsSaga,
  TableOptionRequestSaga,
  RestaurantTablesSaga,
  UserWalletPointsSaga,
} from "../Restaurant/RestaurantSagas";
import { CartSuggestSaga, MenuSaga, ProdSuggestSaga } from "../Menu/MenuSagas";
import {
  OrderSaga,
  VerifyPaymentSaga,
  GetOrderStatusSaga,
  GetOrderHistorySaga,
  GetOrderHistorySagaByPage,
} from "../Order/OrderSagas";
import {
  CheckForDeliveryLocationSaga,
  DistanceDurationSaga,
  locationSaga,
  getCountrySaga,
} from "../Location/LocationSagas";
import { TakeyRestaurantSaga } from "../Takey/Restaurant/RestaurantSagas";
import { TakeyFormsSaga } from "../Takey/Forms/FormSagas";
import { CartSaga, VerifyCouponSaga } from "../Cart/CartSagas";
import { CreateUserSaga, LoginUserSaga } from "../User/UserSagas";
import { HomeRestaurantSaga } from "../Home/HomeSagas";
import { watchCheckVoucher } from "../Voucher/VoucherSaga";
import {
  customerOrdersSaga,
  customerOrderByPageNoSaga,
  customerWalletOrdersSaga,
} from "../Profile/ProfileSaga";

export function* watchSagas() {
  //Combine sagas with
  yield all([
    TableOptionRequestSaga(),
    TableDetailsSaga(),
    GetOrderHistorySaga(),
    CreateUserSaga(),
    LoginUserSaga(),
    ThemeSaga(),
    RestaurantSaga(),
    MenuSaga(),
    OrderSaga(),
    UserWalletPointsSaga(),
    customerOrdersSaga(),
    customerWalletOrdersSaga(),
    customerOrderByPageNoSaga(),
    VerifyPaymentSaga(),
    GetOrderStatusSaga(),
    DistanceDurationSaga(),
    TakeyRestaurantSaga(),
    TakeyFormsSaga(),
    CartSaga(),
    VerifyCouponSaga(),
    HomeRestaurantSaga(),
    RestaurantTablesSaga(),
    ProdSuggestSaga(),
    CartSuggestSaga(),
    CheckForDeliveryLocationSaga(),
    locationSaga(),
    getCountrySaga(),
    watchCheckVoucher(),
    GetOrderHistorySagaByPage(),
  ]);
  // OR
  // yield all([fork(FeatureSaga1)]);
}
