import { call, put, takeLatest, select } from "redux-saga/effects";

import { calculateDistanceDuration } from "./LocationApi";
import * as ACTIONS from "./LocationActions";
import { dispatchErrorMessageAsDialog } from "../../utils/Shared";
import * as TYPES from "./LocationTypes";
// Constants
import { MESSAGES, ERROR_TYPES } from "../../utils/Constants";
import store from "..";
import axios from "axios";

// Replace with your sagas
export function* sagasGetDistanceDuration({ payload = null }) {
  const { hotel } = yield select((state) => state.hotel);
  let messagee = "";

  try {
    // const { priceSummary } = yield select((state) => state.cart);
    const { latitude, longitude } = yield select((state) => state.location);
    const { priceSummary } = yield select((state) => state.cart);

    if (!payload) {
      payload = {
        latitude,
        longitude,
      };
    }
    const { zone, distance, duration, difference, message } = yield call(
      calculateDistanceDuration,
      hotel,
      payload,
      false,
      priceSummary?.subTotal
    );
    if (message) {
      messagee = message;
    }

    if (difference) {
      yield put(ACTIONS.setDifferenceToFreeDelivery(difference));
    } else {
      yield put(ACTIONS.setDifferenceToFreeDelivery(null));
    }
    yield put(
      ACTIONS.getDistanceDurationReceive({
        distance,
        duration,
        ...payload,
        deliveryzone: zone || null,
      })
    );
    // console.log("zoneeeeeeeee", zone);

    yield put(ACTIONS.setIsLocationInvalid(false));
    if (zone == null) {
      dispatchErrorMessageAsDialog({
        type: ERROR_TYPES.INVALID_LOCATION,
        message: messagee,
      });
      yield put(ACTIONS.setIsLocationInvalid(true));
    }
  } catch (error) {
    // console.log("error ", error);
    if (error === MESSAGES.INVALID_LOCATION && hotel) {
      yield put(ACTIONS.setIsLocationInvalid(true));
      dispatchErrorMessageAsDialog({
        type: ERROR_TYPES.INVALID_LOCATION,
        message: MESSAGES.INVALID_LOCATION,
      });

      // clear duration from location state in store
      store.dispatch(ACTIONS.clearDuration());
    }
  }
}

export function* sagasCheckForDeliveryLocation() {
  try {
    const profileDetails = yield select((state) => state.user);
    const { hotel } = yield select((state) => state.hotel);
    const { priceSummary } = yield select((state) => state.cart);

    if (profileDetails?.address?.length) {
      let zoneValue;
      for (const address of profileDetails.address) {
        if (address.geoLoc) {
          const payload = {
            latitude: address.geoLoc.latitude,
            longitude: address.geoLoc.longitude,
          };

          const { zone, distance, duration, difference } = yield call(
            calculateDistanceDuration,
            hotel,
            payload,
            true,
            priceSummary?.subTotal
          );

          if (difference) {
            yield put(ACTIONS.setDifferenceToFreeDelivery(difference));
          } else {
            yield put(ACTIONS.setDifferenceToFreeDelivery(null));
          }
          if (zone) {
            yield put(ACTIONS.setSelectedAddress(address));
          }
        }
      }

      if (!zoneValue && hotel) {
        yield put(ACTIONS.setSelectedAddress(profileDetails.address[0]));
      }
    }
  } catch (err) {
    console.log(
      "Something went wrong while trying to check for delivery location ",
      err
    );
  }
}

function* getLocationData(action) {
  try {
    const { lat, lon } = action.payload;
    const response = yield call(
      axios.get,
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lon}&localityLanguage=en`
    );
    yield put(ACTIONS.setUserCountry(response.data.countryName)); // Dispatch the setUserCountry action to set the user's country in the Redux store
  } catch (error) {
    console.log(error);
  }
}

export function* getUserCountryData(action) {
  try {
    const response = yield call(axios.get, "https://api.ipify.org?format=json");
    const country = yield call(
      axios.get,
      `https://ipapi.co/${response.data.ip}/country/`
    );
    // console.log("country in sagaaaaaaaaaaaaa", country)
    yield put(ACTIONS.getUserCountrySuccess(country));
  } catch (error) {
    console.log("error", error);
  }
}

export function* DistanceDurationSaga() {
  yield takeLatest(TYPES.DISTANCE_DURATION_REQUEST, sagasGetDistanceDuration);
}

export function* CheckForDeliveryLocationSaga() {
  yield takeLatest(
    TYPES.CHECK_FOR_DELIVERY_LOCATION,
    sagasCheckForDeliveryLocation
  );
}

export function* locationSaga() {
  yield takeLatest(TYPES.GET_LOCATION_DATA, getLocationData);
}

export function* getCountrySaga() {
  yield takeLatest(TYPES.GET_USER_COUNTRY_REQUEST, getUserCountryData);
}
