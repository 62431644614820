import * as types from "./ProfileTypes";

const INITIAL_STATE = {
  isCutomerOrderInProgress: false,
  customerOrders: null,
  customerWalletOrders: null,
  customerOrdersPagination: null,
  usedWalletPoints: 0,
  redumptionRatio: null,
  priceWalletPoint: null,
  allWalletPointsUsed: null,
  selectedWallet: null,
};

// Replace with you own reducer
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_CUSTOMER_ORDER_REQUEST:
      return {
        ...state,
        customerOrders: null,
      };
    case types.CUSTOMER_ORDER_RECEIVE:
      return {
        ...state,
        customerOrders: action.payload?.data,
        customerOrdersPagination: action?.payload,
      };
    case types.SET_CUSTOMER_WALLET_ORDERS:
      return {
        ...state,
        customerWalletOrders: action.payload,
      };
    case types.SET_REDUMPTION_RATIO:
      return {
        ...state,
        redumptionRatio: action.payload,
      };
    case types.SET_USED_WALLET_POINT:
      return {
        ...state,
        usedWalletPoints: action.payload,
      };

    case types.SET_PRICE_WALLET_POINT:
      return {
        ...state,
        priceWalletPoint: action.payload,
      };
    case types.CLEAR_USED_WALLET_POINTS:
      return {
        ...state,
        usedWalletPoints: null,
      };
    case types.SET_SELECTED_WALLETS:
      return {
        ...state,
        selectedWallet: action.payload,
      };
    case types.RESET_SELECTED_WALLETS:
      return {
        ...state,
        selectedWallet: null,
      };
    case types.UPDATE_USED_WALLET_POINTS:
      return {
        ...state,
        usedWalletPoints: state.usedWalletPoints - action.payload,
      };
    case types.ADD_USED_WALLET_POINTS:
      return {
        ...state,
        usedWalletPoints: state.usedWalletPoints + action.payload,
      };
    case types.RESET_WALLET:
      return {
        ...state,
        priceWalletPoint: null,
        usedWalletPoints: null,
        redumptionRatio: null,
      };
    default:
      return state;
  }
};

export default reducer;
