// Replace with your request types
export const GET_MENU_REQUEST = "GET_MENU_REQUEST";
export const GET_MENU_RECEIVE = "GET_MENU_RECEIVE";
export const GET_COMBO_MENU_RECEIVE = "GET_COMBO_MENU_RECEIVE";
export const GET_MENU_CATEGORY_LIST_RECEIVE = "GET_MENU_CATEGORY_LIST_RECEIVE";
export const GET_MENU_ON_SEARCH = "GET_MENU_ON_SEARCH";
export const CLEAR_MENU = "CLEAR_MENU";
export const FILTER_MENU_CATEGORY = "FILTER_MENU_CATEGORY";
export const FILTER_MENU_COMBO = "FILTER_MENU_COMBO";
export const SHOW_CATEGORY_HOME_MENU = "SHOW_CATEGORY_HOME_MENU";
export const SET_MENU_VERSION = "SET_MENU_VERSION";
export const IS_HOME_SCROLLED = "IS_HOME_SCROLLED";
export const SET_ACTIVE_CATEGORY = "SET_ACTIVE_CATEGORY";
export const GET_PRODUCT_SUGGESTIONS = "GET_PRODUCT_SUGGESTIONS";
export const SET_PROD_SUGGEST = "SET_PROD_SUGGEST";
export const GET_CART_SUGGESTIONS = "GET_CART_SUGGESTIONS";
export const SET_CART_SUGGEST = "SET_CART_SUGGEST";
