// Replace with your request types
export const SET_RESTAURANT_KEY = "SET_RESTAURANT_KEY";
export const GET_RESTAURANT_REQUEST = "GET_RESTAURANT_REQUEST";
export const GET_RESTAURANT_RECEIVE = "GET_RESTAURANT_RECEIVE";
export const IS_RESTAURANT_RECEIVED = "IS_RESTAURANT_RECEIVED";
export const CLEAR_RESTURANT = "CLEAR_RESTURANT";
export const SET_TABLE_ID = "SET_TABLE_ID";
export const GET_TABLE_DETAILS = "GET_TABLE_DETAILS";
export const SET_TABLE_DETAILS = "SET_TABLE_DETAILS";
export const SET_TABLE_PEOPLE_COUNT = "SET_TABLE_PEOPLE_COUNT";
export const TABLE_OPTION_REQUEST = "TABLE_OPTION_REQUEST";
export const CLEAR_TABLE = "CLEAR_TABLE";
export const GET_RESTAURANT_TABLES = "GET_RESTAURANT_TABLES";
export const GET_RESTAURANT_TABLES_RECEIVE = "GET_RESTAURANT_TABLES_RECEIVE";
export const SET_APP_VERSION = "SET_APP_VERSION";
export const SET_USER_ID = "SET_USER_ID";
export const IS_TODAY_HOLIDAY = "IS_TODAY_HOLIDAY";
export const IS_PROFILE_SECOND_PAGE = "IS_PROFILE_SECOND_PAGE";
export const GET_WALLET_POINTS_REQUEST = "GET_WALLET_POINTS_REQUEST";
export const GET_WALLET_POINTS_RECEIVE = "GET_WALLET_POINTS_RECEIVE";
export const GET_WALLET_POINTS_IN_PROGRESS = "GET_WALLET_POINTS_IN_PROGRESS";
export const RESET_USER_WALLET_DETAILS = "RESET_USER_WALLET_DETAILS";
export const SET_WALLET_ERROR = "SET_WALLET_ERROR";
export const SET_IS_APP_FLAVOUR = "SET_IS_APP_FLAVOUR";
