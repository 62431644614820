import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// Custom hook
import usePrevious from "./usePrevious";

const useProductPrice = (_cartItemId) => {
  const state = useSelector((state) =>
    state.cart.data.find((d) => d._cartItemId === _cartItemId)
  );
  const [product, setProduct] = useState(state);
  const [total, setTotal] = useState(0);
  const [productCount, setProductCount] = useState(1);
  const previousProduct = usePrevious(product);

  // console.log("previoussssssssss", previousProduct);
  useEffect(() => {
    let _total = 0;
    if (product) {
      if (!product?.isWalletRedeemed) {
        if (
          product?.size &&
          product?.size != "" &&
          product?.sizes &&
          product?.sizes != []
        ) {
          const size = product?.sizes?.find(
            (s) => s.id === Number(product.size)
          );
          _total += Number(size?.size_amount);
        } else if (product.sale_price) {
          _total += Number(product.sale_price);
        } else if (product.combo_price) {
          _total += Number(product.combo_price);
        }
      } else {
        if (product?.size && product?.sizes?.length > 0) {
          const size = product?.sizes?.find(
            (s) => s.id === Number(product.size)
          );
          _total += Number(size?.size_amount);
        } else if (product.sale_price) {
          _total += Number(product.sale_price);
        } else if (product?.combo_price) {
          _total += Number(product.combo_price);
        }
      }

      if (product?.count && product?.walletAmount) {
        _total = _total * Number(product.count) - Number(product?.walletAmount);
      } else if (product.count) {
        _total = _total * Number(product.count);
      }
      if (product.modifiers && !product.combo_id) {
        product.modifiers.forEach(
          (m) =>
            (_total +=
              Number(m.price) * Number(m.count) * Number(product.count))
        );
      }
      if (product.combos) {
        if (
          product.combos.comboCatItems &&
          Object.keys(product.combos.comboCatItems).length > 0
        ) {
          for (let key of Object.keys(product.combos.comboCatItems)) {
            if (!key) {
              return;
            }
            for (let c of Object.keys(product.combos.comboCatItems[key])) {
              _total +=
                product.combos.comboCatItems[key][c].extra_price *
                product.combos.comboCatItems[key][c].count;
              // setProductCount(product.combos.comboCatItems[key][c].count)
            }
          }

          for (let key of Object.keys(product.combos.comboItems)) {
            product.combos.comboItems[key].modifiers &&
              product.combos.comboItems[key].modifiers.forEach(
                (m) =>
                  (_total +=
                    Number(m.price) * Number(m.count) * Number(product.count))
              );
          }
        }
        if (
          product.combos.comboCatItems &&
          Object.keys(product.combos.comboCatItems).length > 0
        ) {
          for (let key of Object.keys(product.combos.comboCatItems)) {
            if (
              product.combos.comboCatItems[key] &&
              Object.keys(product.combos.comboCatItems[key]).length > 0
            ) {
              for (let p of Object.keys(product.combos.comboCatItems[key])) {
                product.combos.comboCatItems[key][p].modifiers &&
                  product.combos.comboCatItems[key][p].modifiers.forEach(
                    (m) =>
                      (_total +=
                        Number(m.price) *
                        Number(m.count) *
                        Number(product.count))
                    // Number(product.combos.comboCatItems[key][p].count))
                    //combo modifier price
                    // Number(m.price) *
                    // Number(m.count) *
                  );
              }
            }
          }
        }
      }
    }

    setTotal(_total.toFixed(2));
  }, [product, previousProduct]);

  return { total, setProduct };
};

export default useProductPrice;

// const usePriceSummary = (initialState = null) => {
//     const [product, setProduct] = useState(initialState);
//     const [total, setTotal] = useState(0);

//     useEffect(() => {
//         let _total = 0;
//         if(product){
//             if(product.sale_price) {
//                 _total += product.sale_price
//             }
//             if(product.modifiers) {
//                 product.modifiers.forEach(m => _total += m.price)
//             }
//             if(product.count) {
//                 _total = _total * product.count
//             }
//         }
//         setTotal(_total)
//     }, [product,product.count]);

//     return { total, setProduct };
// };

// export default usePriceSummary;
