import { makeStyles } from "@material-ui/core/styles";
import { calculateFontSize } from "../../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  inputUnderline: {
    borderBottom: "none",
    "&::before": {
      borderBottom: "none",
      "&::focus": {
        borderBottom: "none",
      },
    },
  },
  input: {
    width: "100%",
    padding: "0 6%",
    borderRadius: "10px",
    height: "48px",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    lineHeight: "21px",
  },
  loginBtn: {
    height: 50,
    fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
    border: "1px solid",
    borderRadius: theme.shape.borderRadius,
    textTransform: "none",
    textDecoration: " none",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
    },
  },
  otpInput: {
    width: "40px !important",
    height: "40px !important",
    border: "1px solid #686b78",
    borderRadius: 6,
    margin: 5,
    "@media(max-width: 780px)": {
      width: "30px !important",
      height: "30px !important",
    },
  },
  textCon: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "16px",
  },
  text: {
    cursor: "pointer",
    fontWeight: "bold",
    paddingLeft: "8px",
    // display: "flex",
    // alignItems: "center",
  },
}));

export default useStyles;
