import { call, put, takeLatest } from "redux-saga/effects";
import {
  getCustomerOrderByPageNo,
  getCustomerOrders,
  getCustomerWalletOrders,
} from "./ProfileApis";
import * as TYPES from "./ProfileTypes";
import * as ACTIONS from "./ProfileActions";

export function* sagasGetCustomerOrders(payload) {
  try {
    const data = yield call(getCustomerOrders, payload);
    yield put(ACTIONS.getCustomerOrderReceive(data));
  } catch (error) {
    console.log("errorrrrr", error);
  }
}

export function* sagasGetCustomerWalletOrders(payload) {
  try {
    const data = yield call(getCustomerWalletOrders, payload?.payload);
    yield put(ACTIONS.setCustomerWalletOrders(data?.data));
  } catch (error) {
    console.log("errorrrrr", error);
  }
}

export function* sagaGetCustomerOrderByPageNo(payload) {
  try {
    const response = yield call(getCustomerOrderByPageNo, payload);
    yield put(ACTIONS.getCustomerOrderReceive(response));
  } catch (error) {}
}

export function* customerOrdersSaga() {
  yield takeLatest(TYPES.GET_CUSTOMER_ORDER_REQUEST, sagasGetCustomerOrders);
}

export function* customerWalletOrdersSaga() {
  yield takeLatest(
    TYPES.GET_CUSTOMER_WALLET_REQUEST,
    sagasGetCustomerWalletOrders
  );
}

export function* customerOrderByPageNoSaga() {
  yield takeLatest(
    TYPES.GET_CUSTOMER_HISTORY_BY_PAGE_NO,
    sagaGetCustomerOrderByPageNo
  );
}
