import { axiosInstance } from "../../network/apis";
import { API } from "../../utils/Constants";

export const getCustomerOrders = async (payload) => {
  const { data } = await axiosInstance.post(
    `${API.ENDPOINTS.CUSTOMER_ORDERS}`,
    {
      uid: payload?.payload?.uid,
      online_key: payload?.payload?.online_key,
    },
    { handlerEnabled: false }
  );

  if (data.status === false) {
    throw new Error(data.message);
  }
  return data;
};

export const getCustomerWalletOrders = async (payload) => {
  // console.log("payloadddddddddddddd", payload);
  try {
    const { data } = await axiosInstance.get(
      `${API.ENDPOINTS.GET_WALLET_HISTORY}`,
      {
        params: {
          customer_id: payload,
        },
        handlerEnabled: false,
      }
    );
    // console.log("dataaaaaaaaaa", data);

    if (data.status === false) {
      throw new Error(data.message);
    }
    return data;
  } catch (error) {
    console.error("Error fetching customer wallet orders:", error);
    throw error;
  }
};

export const getCustomerOrderByPageNo = async (payload) => {
  const { data } = await axiosInstance.post(
    `${API.ENDPOINTS.CUSTOMER_ORDERS}?page=${payload?.payload?.pageNo}`,
    {
      uid: payload?.payload?.uid,
      online_key: payload?.payload?.online_key,
    },
    { handlerEnabled: false }
  );
  if (data.status === false) {
    throw new Error(data.message);
  }
  return data;
};
