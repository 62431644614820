import { makeStyles } from "@material-ui/core/styles";
import {
  calculateFontSize,
  setFontColorOnBg,
} from "../../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  layoutPadding: {
    padding: theme.spacing(2.4, 1.6, 0, 1.6),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2.4, 2, 0, 2),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2.4, 12, 0, 12),
    },
  },
  bodyContainer: {
    // height: "calc(100% - 85px - 80px)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      // height: "calc(100% - 85px)",
    },
  },
  flexItemContainer: {
    width: "100%",
    paddingBottom: theme.spacing(0.8),
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  leftContainer: {
    [theme.breakpoints.up("sm")]: {
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(6.3),
    },
  },
  rightContainer: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(6.3),
    },
  },
  CouponDesktopContainer: {
    width: "100%",
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  PriceSummaryDesktopContainer: {
    width: "100%",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  footerContainer: {
    height: "80px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  fixed: {
    position: "fixed",
    bottom: 0,
  },
  PriceSummaryMobileContainer: {
    display: "flex",
    backgroundColor: theme.palette.common.background,
    padding: theme.spacing(1.6),
    boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.08)",
    boxSizing: "border-box",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  totalAmountContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  totalAmountText: {
    fontSize: calculateFontSize(12, theme.palette.common.fontVariation),
    lineHeight: "18px",
    color: theme.palette.common.themeFontColor,
  },
  totalAmountButton: {
    fontSize: calculateFontSize(20, theme.palette.common.fontVariation),
    fontWeight: "bold",
    backgroundColor: "transparent",
    textTransform: "none",
    color: theme?.palette?.common?.themeFontColor,
    boxShadow: "none",
    padding: theme.spacing(0),
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      boxShadow: "none",
    },
  },
  btn: {
    fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
    backgroundColor:
      theme?.palette?.button?.buttonColor || theme.palette.button.main,
    border: theme?.palette?.button?.buttonBorder ? "1px solid black" : null,
    borderRadius:
      theme?.palette?.button?.borderRadius || theme.shape.borderRadius,
    textTransform: "none",
    color: setFontColorOnBg(
      theme?.palette?.button?.buttonColor || theme.palette.button.main
    ),
    textDecoration: " none",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
    },
  },
  drawerPaper: {
    backgroundColor: theme.palette.card.cardColor,
    width: 295,
    [theme.breakpoints.up("sm")]: {
      width: 350,
    },
    [theme.breakpoints.up("md")]: {
      width: 592,
    },
  },
  spBtn: {
    width: "100%",
    marginLeft: "20px",
  },
}));

export default useStyles;
