import { call, put, takeLatest, select, delay } from "redux-saga/effects";
// import Cookies from "universal-cookie";
import {
  order,
  verifyPayment,
  getOrderStatus,
  getOrderHistory,
  getOrderHistoryByPage,
} from "./OrderApi";
import * as ACTIONS from "./OrderActions";
import { dispatchSnackbarError } from "../../utils/Shared";
import * as TYPES from "./OrderTypes";

import { ORDER_STATUS } from "../../utils/Constants";
import useCheckoutForm from "../../CustomHooks/useCheckoutForm";

// const cookies = new Cookies();

// Replace with your sagas
export function* sagasOrderRequest() {
  try {
    // console.log("zzz");
    const hotel = yield select((state) => state.hotel);
    const cart = yield select((state) => state.cart);
    const location = yield select((state) => state.location);
    const user = yield select((state) => state.user);
    const voucherData = yield select((state) => state.voucher);
    const profile = yield select((state) => state.profile);
    // const formValues = yield select((state) => state.cart.checkoutDetails);

    // const
    // console.log("cart in sagaaa", cart)

    const response = yield call(
      order,
      hotel,
      cart,
      location,
      user,
      voucherData,
      profile
    );
    // console.log("responseeee in sagaaaaaaaaaaaaa", response)
    // console.log("response ffff", response.data)
    yield put(ACTIONS.orderReceive(response.data));
  } catch (err) {
    if (err?.response?.status === 400) {
      if (err?.response?.data?.ecode) {
        if (
          err?.response?.data?.ecode === 100 ||
          err?.response?.data?.ecode === 101
        ) {
          yield put(ACTIONS.setErrorModalInCheckoutOpen());
        } else if (err?.response?.data?.ecode === 800) {
          yield put(ACTIONS.setErrorModalInCheckoutClose());
          yield put(ACTIONS.setUberFailedModal(err?.response?.data?.message));
        } else {
          yield put(ACTIONS.setErrorModalInCheckoutClose());
          dispatchSnackbarError("Something went wrong, please try again later");
        }
      } else {
        yield put(ACTIONS.setErrorModalInCheckoutClose());
        dispatchSnackbarError("Something went wrong, please try again later");
      }
    } else {
      // console.log("working sagaaaaa",err?.response?.status)
      yield put(ACTIONS.setErrorModalInCheckoutClose());
      dispatchSnackbarError("Something went wrong, please try again later");
    }
    // console.log("order faileddddddddd")
    yield put(ACTIONS.orderFailed());
  }
}

export function* sagasVerifyPaymentRequest({ payload }) {
  const maxRetries = 3;
  let retryCount = 0;

  while (retryCount < maxRetries) {
    try {
      const response = yield call(verifyPayment, payload?.orderId);

      yield put(
        ACTIONS.setPaymentStatusCode(response?.status || response?.errorStatus)
      );

      if (response?.status === 200) {
        yield put(ACTIONS.verifyPaymentReceive(response?.data));
        payload?.callBack(payload?.orderId, response?.status);
        return; // Exit the loop if status is 200
      }

      if (
        response?.data?.message ||
        response?.errorMsg ||
        response?.status !== 206
      ) {
        yield put(
          ACTIONS.updateFailedMsg(response?.data?.message || response?.errorMsg)
        );
        payload?.callBack(
          payload?.orderId,
          response?.status || response?.errorStatus
        );
        return; // Exit the loop if status is not 206 or there is an error
      }

      yield put(ACTIONS.verifyPaymentReceive(response?.data));
      payload?.callBack(payload?.orderId, response?.status);

      if (retryCount < maxRetries - 1) {
        yield delay(3000); // Add a delay before retrying (adjust as needed)
      }

      retryCount++;
    } catch (err) {
      // Handle other errors if needed
      console.log("working 50000000000000");
      payload?.callBack(payload?.orderId, 500);
      yield put(ACTIONS.setPaymentStatusCode(500));
      console.error(err);
      return;
    }
  }

  // If the loop completes without returning, handle the case here
  const res = {
    message: "Thank you... Your order is being sent to the shop..",
    invalidResponse: true,
  };

  yield put(ACTIONS.verifyPaymentReceive(res));
  dispatchSnackbarError(
    "Thank you. Your order is being sent to the shop. You will get a confirmation email once the shop has accepted the order. Please keep an eye on your inbox."
  );
}

// export function* sagasVerifyPaymentRequest({ payload }) {
//   let retryCount = 0;
//   const maxRetries = 3;

//   try {
//     while (retryCount < maxRetries) {
//       const response = yield call(verifyPayment, payload?.orderId);
//       console.log("responseeeeee", response);
//       yield put(
//         ACTIONS.setPaymentStatusCode(response?.status || response?.errorStatus)
//       );

//       if (
//         response?.data?.message !== "" ||
//         response?.errorMsg !== "" ||
//         response?.status !== 206
//       ) {
//         yield put(
//           ACTIONS.updateFailedMsg(response?.data?.message || response?.errorMsg)
//         );
//         payload?.callBack(
//           payload?.orderId,
//           response?.status || response?.errorStatus
//         );
//         return; // Exit the loop if status is not 206 or there is an error
//       }

//       yield put(ACTIONS.verifyPaymentReceive(response?.data));
//       payload?.callBack(payload?.orderId, response?.status);

//       return; // Exit the function after processing the response
//     }
//   } catch (err) {
//     const res = {
//       message: "Thank you... Your order is being sent to the shop..",
//       invalidResponse: true,
//     };

//     yield put(ACTIONS.verifyPaymentReceive(res));
//     dispatchSnackbarError(
//       "Thank you. Your order is being sent to the shop. You will get a confirmation email once the shop has accepted the order. Please keep an eye on your inbox."
//     );
//   }
// }

// export function* sagasVerifyPaymentRequest({ payload }) {
//   // console.log("order id in sagaaaaaaaaaaaaaaaa", payload?.orderId)

//   let retryCount = 0;
//   const maxRetries = 3;

//   try {
//     while (retryCount < maxRetries) {
//       const response = yield call(verifyPayment, payload?.orderId);
//       // console.log("responseeeeeeee", response);
//       yield put(
//         ACTIONS.setPaymentStatusCode(response?.status || response?.errorStatus)
//       );
//       if (
//         response?.data?.message != "" ||
//         response?.errorMsg != "" ||
//         undefined
//       ) {
//         yield put(
//           ACTIONS.updateFailedMsg(response?.data?.message || response?.errorMsg)
//         );
//       }
//       // console.log("errorrrrr", response?.status || response?.errorStatus)
//       payload?.callBack(
//         payload?.orderId,
//         response?.status || response?.errorStatus
//       );
//       if (response?.status == 200) {
//         yield put(ACTIONS.verifyPaymentReceive(response?.data));
//         return;
//       } else {
//         yield delay(1000); // Delay for one second
//         retryCount++;
//       }
//     }
//     // if()
//   } catch (err) {
//     const res = {
//       message: "Thank you... Your order is been send to the shop..",
//       invalidResponse: true,
//     };

//     yield put(ACTIONS.verifyPaymentReceive(res));
//     dispatchSnackbarError(
//       "Thank you. Your order is been send to the shop, you will get a confirmation email once shop is accepted the order, please keep an eye on you inbox."
//     );
//   }
// }
// export function* sagasGetOrderStatusRequest() {
//   try {
//     const { orderId } = yield select((state) => state.order);
//     const response = yield call(getOrderStatus, orderId);
//     yield put(ACTIONS.getOrderStatusReceive(response.data));
//   } catch (err) {
//     console.log("error ", err);
//   }
// }
// export function* sagasGetOrderStatusRequest({ orderId }) {
//   for (let i = 0; i < 4; i++) {
//     try {
//       // const orderstatus =
//       //   i === 3 ? ORDER_STATUS.ACCEPTED : ORDER_STATUS.PENDING;
//       // console.log(`${orderId} == ${orderstatus}`);

//       const response = yield call(getOrderStatus, orderId);
//       const { orderstatus } = response.data;
//       // console.log("responseeeeee", response);
//       // console.log("orderstatyssssssssss", orderstatus);

//       if (orderstatus === ORDER_STATUS.PENDING && i !== 3) {
//         yield delay(10000);
//         console.log("RETRYING FOR ORDER STATUS");
//       } else {
//         console.log("UPDATING ORDER STATUS TO STORE");
//         // console.log("order status afterrrr", orderstatus)
//         // yield put(ACTIONS.getOrderStatusReceive(orderstatus));
//         yield put(ACTIONS.getOrderStatusReceive(response?.data));

//         // yield put(ACTIONS.getOrderStatusReceive( orderstatus ));
//       }
//     } catch (error) {
//       // dispatchSnackbarError(error.message);
//       // throw new Error(error);
//       const res = {
//         message: "Your order has been successfully placed !",
//         invalidResponse: true,
//       };
//       yield put(ACTIONS.getOrderStatusReceive(res));
//     }
//   }
// }

const maxRetries = 3;

export function* sagasGetOrderStatusRequest({ orderId }) {
  // Retrieve the retry count from sessionStorage or default to 0
  let retryCount =
    parseInt(sessionStorage.getItem(`orderStatusRetryCount_${orderId}`)) || 0;

  for (; retryCount < maxRetries; retryCount++) {
    // console.log("retry countttttttt", retryCount);
    // console.log("order iddddddd in sagaaaaaaaaaa", orderId);
    try {
      const response = yield call(getOrderStatus, orderId);
      const { orderstatus } = response.data;

      if (orderstatus === ORDER_STATUS.ACCEPTED) {
        yield put(ACTIONS.getOrderStatusReceive(response?.data));
        // Reset the retry count after a successful response
        retryCount = 3;
        sessionStorage.removeItem(`orderStatusRetryCount_${orderId}`);
        return;
      }

      if (
        orderstatus === ORDER_STATUS.PENDING &&
        retryCount !== maxRetries - 1
      ) {
        yield delay(10000);
        console.log("RETRYING FOR ORDER STATUS");
      } else {
        console.log("UPDATING ORDER STATUS TO STORE");
        yield put(ACTIONS.getOrderStatusReceive(response?.data));
        sessionStorage.removeItem(`orderStatusRetryCount_${orderId}`);
      }
    } catch (error) {
      const res = {
        message: "Your order has been successfully placed!",
        invalidResponse: true,
      };
      yield put(ACTIONS.getOrderStatusReceive(res));
    }
  }

  // Save the retry count to sessionStorage
  sessionStorage.setItem(
    `orderStatusRetryCount_${orderId}`,
    retryCount.toString()
  );
}

export function* sagasGetOrderHistory({ cusId }) {
  try {
    yield put(ACTIONS.updateOrderRequestLoading(true));
    const response = yield call(getOrderHistory, cusId);
    if (response?.data?.data?.data) {
      yield put(ACTIONS.orderHistory(response?.data?.data?.data?.reverse()));
      yield put(ACTIONS.updateOrderRequestLoading(false));
      yield put(ACTIONS.setOrderPagination(response?.data?.data));
    } else {
      // throw new Error("Something went wrong, please try again later");
      return response;
    }
  } catch (err) {
    if (err?.response?.status === 400) {
      dispatchSnackbarError(err?.response?.data?.status);
    } else {
      dispatchSnackbarError("Something went wrong, please try again later");
    }
    // yield put(ACTIONS.orderFailed());
  }
}

export function* sagasGetOrderHistoryByPage({ cusId, pageNo }) {
  try {
    yield put(ACTIONS.updateOrderRequestLoading(true));
    const response = yield call(getOrderHistoryByPage, cusId, pageNo);
    if (response?.data?.data?.data) {
      yield put(ACTIONS.orderHistory(response?.data?.data?.data?.reverse()));
      yield put(ACTIONS.updateOrderRequestLoading(false));
      yield put(ACTIONS.setOrderPagination(response?.data?.data));
    } else {
      // throw new Error("Something went wrong, please try again later");
      return response;
    }
  } catch (err) {
    if (err?.response?.status === 400) {
      dispatchSnackbarError(err?.response?.data?.status);
    } else {
      dispatchSnackbarError("Something went wrong, please try again later");
    }
    // yield put(ACTIONS.orderFailed());
  }
}

const clearCookies = () => {
  // cookies.remove("orderId");
  // cookies.remove("paymentId");
  // cookies.remove("hotelKey");
  // cookies.remove("paymentMethod");
};

export function* OrderSaga() {
  yield takeLatest(TYPES.ORDER_REQUEST, sagasOrderRequest);
}
export function* VerifyPaymentSaga() {
  yield takeLatest(TYPES.VERIFY_PAYMENT_REQUEST, sagasVerifyPaymentRequest);
}
export function* GetOrderStatusSaga() {
  yield takeLatest(TYPES.ORDER_STATUS_REQUEST, sagasGetOrderStatusRequest);
}
export function* GetOrderHistorySaga() {
  yield takeLatest(TYPES.GET_ORDER_HISTORY, sagasGetOrderHistory);
}
export function* GetOrderHistorySagaByPage() {
  yield takeLatest(
    TYPES.GET_ORDER_HISTORY_BY_PAGE_NO,
    sagasGetOrderHistoryByPage
  );
}
export function* ClearCookies() {
  yield takeLatest(TYPES.CLEAR_COMPLETE_ORDER, clearCookies);
}
