import { makeStyles } from "@material-ui/core/styles";
import {
  calculateFontSize,
  setFontColorOnBg,
} from "../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    height: 0,
  },
  btnCon: {
    position: "absolute",
    textTransform: "none",
    zIndex: 1,
    bottom: 10,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  btnScrollCon: {
    position: "absolute",
    textTransform: "none",
    zIndex: 1,
    bottom: 10,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  btn: {
    margin: "0 1rem",
    width: "150px",
    color: setFontColorOnBg(theme?.palette?.button?.buttonColor || "#000000"),
    fontSize: calculateFontSize(12, theme.palette.common.fontVariation),
    padding: theme.spacing(1.05, 1.6),
    backgroundColor: theme?.palette?.button?.buttonColor || "black",
    lineHeight: "18.79px",
    textTransform: "capitalize",
    borderRadius: theme?.palette?.button?.borderRadius || "12px",
    border: theme?.palette?.button?.buttonBorder ? "1px solid black" : null,
    // ...theme.typography.rockwell,
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  spclBtn: {
    margin: "0 1rem",
    width: "320px",
    // backgroundColor:
    //   theme?.palette?.button?.buttonColor || theme.palette.button.main,
    fontSize: calculateFontSize(12, theme.palette.common.fontVariation),
    borderRadius:
      theme?.palette?.button?.borderRadius || theme?.shape?.borderRadius,
    padding: theme.spacing(1.05, 1.6),
    lineHeight: "18.79px",
    textTransform: "capitalize",
    color: setFontColorOnBg(theme?.palette?.button?.buttonColor || "#000000"),
    // ...theme.typography.rockwell,
    backgroundColor: theme?.palette?.button?.buttonColor || "black",
    border: theme?.palette?.button?.buttonBorder ? "1px solid black" : null,
    "&:hover": {
      color: "#fff",
    },
  },
  specialBtn: {
    margin: "0 1rem",
    width: "70px",
    color: setFontColorOnBg(theme?.palette?.button?.buttonColor || "#000000"),
    fontSize: calculateFontSize(12, theme.palette.common.fontVariation),
    borderRadius:
      theme?.palette?.button?.borderRadius || theme?.shape?.borderRadius,
    padding: theme.spacing(1.05, 1.6),
    lineHeight: "18.79px",
    textTransform: "capitalize",
    backgroundColor: theme?.palette?.button?.buttonColor || "black",
    border: theme?.palette?.button?.buttonBorder ? "1px solid black" : null,
    // ...theme.typography.rockwell,
    "&:hover": {
      color: "#fff",
      backgroundColor: "black",
      cursor: "pointer",
    },
  },
}));

export default useStyles;
